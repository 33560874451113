@font-face{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/SourceSansPro-Regular.ttf');
}

@font-face{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/SourceSansPro-SemiBold.ttf');
}

@font-face{
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: url('fonts/SourceSansPro-Bold.ttf');
}