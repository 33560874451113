
.scope_id_scopes_select_filter label { position: relative; }
.scope_id_scopes_select_filter option { padding: 3px 4px; }
.scope_id_scopes_select_filter optgroup::before { padding: 3px 4px; }
select.scopes-expanded {
  overflow-y: hidden;
  /* scroll-behavior: initial; */
  height: 2.4375rem;
  top: 0;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2844, 41, 48%29'></polygon></svg>");
}
select.scopes-expanded:focus {
  height: 200px;
  position: absolute;
  left: 25%;
  overflow-y: scroll;
  /* scroll-behavior: unset; */
  background-image: none;
  z-index: 20;
}

.card__support__data--left.custom {
  max-width: 100%;
}

.card__support__data--left.custom .text-ellipsis {
  overflow: unset;
}
.card__support__data--left.custom strong {
  overflow: unset;
  white-space: normal;line-height: 1.2;
  display: block;
  margin: 10px 0;
}

.card__support .card__button.centered { margin: auto; }